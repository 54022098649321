.container {
    position: absolute;
    top: 64px;
    width: 100%;
}

.wrapper {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    width: 48vw;
    display: flex;
    box-shadow: 0px 0px 5px 0px black;
    border-radius:4px;
    justify-content: center;
    padding: 64px;
    position: relative;
}

.close {
    cursor: pointer;
    font-size: 32px;
    position: absolute;
    top: 8px;
    right: 16px;
}

.image {
    width: 48vw;
}
