.container {
    color: white;
    min-height: 768px;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.h1 {
    padding: 3vh 0 0 0;
    margin: 0;
    text-shadow: 2px 2px 2px black;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

.h3 {
    margin-top: 1vh;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
}

.p {
    margin-top: 10vh;
}

.text-wrapper {
    animation: fade 1s ease;
    width: 38%;
    margin-left: 3vw;
    font-size: 18px;
    text-align: left;
}

@media screen and (max-width: 600px) {
    .text-wrapper {
        width: 60%;
    }
}
