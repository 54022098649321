.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "header"
        "main"
        "footer"
    ;
}

.header {
    grid-area: header;
}
