.footer-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 2vw;
    display: flex;
    justify-content: space-around;
}

.footer-contact {
    font-size: 18px;
}

.footer-address {
    font-size: 16px;
    text-align: left;
}

.footer-address-text {
    margin-left: 16px;
}

.footer-address-title {
    font-size: 18px;
}

.footer-phone {
    font-size: 18px;
    color: black;
}

.footer-phone-div {
    margin: 16px;
}

.footer-phone-icon {
    width: 40px;
    vertical-align: middle;
    margin-right: 12px;
}
