.container {
    font-size: 18px;
    box-shadow: 0px 0px 3px 0px rgb(75, 75, 75);
    padding: 4px;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.li {
    margin: 0 24px 0 24px;
    display: inline;
    cursor: pointer;
    color: black;
    transition: 0.3s;
    padding: 0 8px 8px 8px;
    border-bottom: 1px solid white;
}

.active {
    color:rgb(100, 100, 100);
    border-bottom: 1px solid rgb(156, 156, 156);
}

.li:hover {
    color:rgb(100, 100, 100);
    border-bottom: 1px solid rgb(156, 156, 156);
}
