.container {
    color: white;
    min-height: 768px;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    animation: fade 1s ease;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding: 32px;
}

.h1 {
    margin: 0;
    padding: 16px 0;
    text-align: center;
    text-shadow: 1px 1px 3px black;
}

.video {
    padding: 8px;
    background-color: rgba(90, 90, 90, 0.5);
    border-radius: 32px;
    box-shadow: 0px 0px 5px 0px black;
}
