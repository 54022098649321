.container {
    color: white;
    min-height: 768px;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    animation: fade 1s ease;
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.h1 {
    margin: 0;
    padding: 16px;
    text-align: center;
    text-shadow: 1px 1px 3px black;
}

.urn {
    padding: 8px;
    margin: 24px;
    box-shadow: 0px 0px 2px 0px rgb(92, 92, 92);
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    width: 256px;
    height: 352px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(136, 136, 136, 0.33);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.overlay:hover {
    opacity: 0;
}
