.container {
    color: white;
    min-height: 768px;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.text-wrapper {
    animation: fade 1s ease;
    width: 40%;
    margin: 0;
}

.p {
    margin: 0px;
    padding: 32px;
    font-weight: 200;
    font-size: 18px;
}

.title {
    margin: 0;
    padding: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
}
